import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtejundiai.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3525904',
  schema: 'pbi_dte_jundiai',
  groupId: 'aaa61f13-82be-40df-88ad-508b00c0384f',
  analiseGeral: '2081601e-e61f-403e-a932-118e4387630d',
  analiseUsuarios: 'ef5d5c1d-a5ee-4a02-9e03-7c98f56d8dc8',
  analiseComunicados: '6afd23c7-8511-4da1-a5fd-fac49a0c4c0e',
  analiseProcurador: '7d93b8d2-8344-49ab-8d66-c9e8ccb789d8',
  analiseEventos: '754749e1-b7cf-4ccf-a3b1-304c8b5b3301'
};
